import React, { FC, useState } from 'react';
import { Form, Header, Dropdown, Button, Radio } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { isEqual } from 'lodash';

import {
  HistogramGroupBy,
  HistogramPeriod,
  JoinDa,
  Party,
  Site,
} from '../../API';
import CountrySelect from '../CountrySelect';
import VotingStateSelect from '../VotingStateSelect';
// import CongressionalDistrictSelect from '../CongressionalDistrictSelect';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  startDate: new Date(startDateUtc),
  endDate: new Date(),
  countries: [],
  votingStates: [],
  congressionalDistricts: [],
  joinDa: null,
  party: null,
  site: null,
  recordType: 'COMPLETED',
  period: HistogramPeriod.DAY,
  groupBy: null,
};

export interface LogTimeseriesControlQuery {
  startDate: Date | null;
  endDate: Date | null;
  countries: string[];
  votingStates: string[];
  congressionalDistricts: string[];
  joinDa: JoinDa | null;
  party: Party | null;
  site: Site | null;
  recordType: string;
  period: HistogramPeriod;
  groupBy: HistogramGroupBy | null;
}

interface LogTimeseriesControlsProps {
  onQuery: (data: LogTimeseriesControlQuery) => void;
  isLoading: boolean;
}

const LogTimeseriesControls: FC<LogTimeseriesControlsProps> = ({
  onQuery,
  isLoading,
}) => {
  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);
  const [countries, setCountries] = useState<string[]>(
    INITIAL_FORM_STATE.countries,
  );
  const [votingStates, setVotingStates] = useState<string[]>(
    INITIAL_FORM_STATE.votingStates,
  );
  const [congressionalDistricts, setCongressionalDistricts] = useState<
    string[]
  >(INITIAL_FORM_STATE.congressionalDistricts);
  const [joinDa, setJoinDa] = useState<JoinDa | null>(
    INITIAL_FORM_STATE.joinDa,
  );
  const [party, setParty] = useState<Party | null>(INITIAL_FORM_STATE.party);
  const [site, setSite] = useState<Site | null>(INITIAL_FORM_STATE.site);
  const [recordType, setRecordType] = useState(INITIAL_FORM_STATE.recordType);
  const [period, setPeriod] = useState<HistogramPeriod>(
    INITIAL_FORM_STATE.period,
  );
  const [groupBy, setGroupBy] = useState<HistogramGroupBy>(
    INITIAL_FORM_STATE.groupBy,
  );

  const resetForm = () => {
    setStartDate(INITIAL_FORM_STATE.startDate);
    setEndDate(INITIAL_FORM_STATE.endDate);
    setCountries(INITIAL_FORM_STATE.countries);
    setVotingStates(INITIAL_FORM_STATE.votingStates);
    setCongressionalDistricts(INITIAL_FORM_STATE.congressionalDistricts);
    setJoinDa(INITIAL_FORM_STATE.joinDa);
    setParty(INITIAL_FORM_STATE.party);
    setSite(INITIAL_FORM_STATE.site);
    setRecordType(INITIAL_FORM_STATE.recordType);
    setPeriod(INITIAL_FORM_STATE.period);
    setGroupBy(INITIAL_FORM_STATE.groupBy);
  };

  const formHasChanges = !isEqual(INITIAL_FORM_STATE, {
    startDate,
    endDate,
    countries,
    votingStates,
    congressionalDistricts,
    joinDa,
    party,
    site,
    recordType,
    period,
    groupBy,
  });

  const collateQueryData = (): LogTimeseriesControlQuery => ({
    startDate,
    endDate,
    countries,
    votingStates,
    congressionalDistricts,
    joinDa,
    party,
    site,
    recordType,
    period,
    groupBy,
  });

  return (
    <Form>
      <Header as="h2">Usage over time</Header>
      <Form.Field>
        <label>Period</label>
        <Form.Group inline>
          <Form.Field
            control={Radio}
            label="Day"
            checked={period === HistogramPeriod.DAY}
            onChange={() => setPeriod(HistogramPeriod.DAY)}
          />
          <Form.Field
            control={Radio}
            label="Week"
            checked={period === HistogramPeriod.WEEK}
            onChange={() => setPeriod(HistogramPeriod.WEEK)}
          />
          <Form.Field
            control={Radio}
            label="Month"
            checked={period === HistogramPeriod.MONTH}
            onChange={() => setPeriod(HistogramPeriod.MONTH)}
          />
        </Form.Group>
      </Form.Field>
      <Form.Field>
        <label>Start date</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>End date</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Group grouped>
        <label>Record type</label>
        <Form.Radio
          label="Completed"
          value="COMPLETED"
          checked={recordType === 'COMPLETED'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="Incomplete"
          value="INCOMPLETE"
          checked={recordType === 'INCOMPLETE'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="All"
          value="ALL"
          checked={recordType === 'ALL'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
      </Form.Group>
      <Form.Field>
        <label>Country</label>
        <CountrySelect
          multiple
          search
          value={countries}
          selection
          onChange={(_evt, data) => setCountries(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Voting State</label>
        <VotingStateSelect
          search
          multiple
          value={votingStates}
          selection
          onChange={(_evt, data) => setVotingStates(data.value)}
        />
      </Form.Field>
      {/* <Form.Field> */}
      {/*  <label>Congressional District</label> */}
      {/*  <CongressionalDistrictSelect */}
      {/*    search */}
      {/*    multiple */}
      {/*    value={congressionalDistricts} */}
      {/*    selection */}
      {/*    onChange={(_evt, data) => setCongressionalDistricts(data.value)} */}
      {/*  /> */}
      {/* </Form.Field> */}
      <Form.Field>
        <label>Join DA</label>
        <Dropdown
          clearable
          value={joinDa}
          options={[
            { key: 'ALL', value: null, text: '' },
            { key: JoinDa.TRUE, value: JoinDa.TRUE, text: 'True' },
            { key: JoinDa.FALSE, value: JoinDa.FALSE, text: 'False' },
            {
              key: JoinDa.ALREADY_A_MEMBER,
              value: JoinDa.ALREADY_A_MEMBER,
              text: 'Already a member',
            },
          ]}
          selection
          onChange={(_evt, data) => setJoinDa(data.value as JoinDa)}
        />
      </Form.Field>
      <Form.Field>
        <label>Party</label>
        <Dropdown
          clearable
          value={party}
          options={[
            { key: 'ALL', value: null, text: '' },
            {
              key: Party.DEMOCRATIC,
              value: Party.DEMOCRATIC,
              text: 'Democratic',
            },
            {
              key: Party.REPUBLICAN,
              value: Party.REPUBLICAN,
              text: 'Republican',
            },
            {
              key: Party.OTHER,
              value: Party.OTHER,
              text: 'Other Party',
            },
            {
              key: Party.PREFER_NOT_TO_ANSWER,
              value: Party.PREFER_NOT_TO_ANSWER,
              text: 'Prefer not to answer',
            },
            {
              key: Party.NO_PARTY_PREFERENCE,
              value: Party.NO_PARTY_PREFERENCE,
              text: 'No party preference',
            },
          ]}
          selection
          onChange={(_evt, data) => setParty(data.value as Party)}
        />
      </Form.Field>
      <Form.Field>
        <label>Site</label>
        <Dropdown
          clearable
          value={site}
          options={[
            {
              key: Site.STANDARD,
              value: Site.STANDARD,
              text: 'VFA Standard',
            },
            {
              key: Site.SPANISH,
              value: Site.SPANISH,
              text: 'VFA Spanish',
            },
            {
              key: Site.STUDENT,
              value: Site.STUDENT,
              text: 'Students',
            },
            {
              key: Site.BETA,
              value: Site.BETA,
              text: 'Beta',
            },
          ]}
          selection
          onChange={(_evt, data) => setSite(data.value as Site)}
        />
      </Form.Field>
      <Form.Field>
        <label>Group By</label>
        <Dropdown
          value={groupBy}
          options={[
            { key: 'ALL', value: null, text: '' },
            // {
            //   key: HistogramGroupBy.CONGRESSIONAL_DISTRICT,
            //   value: HistogramGroupBy.CONGRESSIONAL_DISTRICT,
            //   text: 'Congressional district',
            // },
            {
              key: HistogramGroupBy.COUNTRY,
              value: HistogramGroupBy.COUNTRY,
              text: 'Country',
            },
            {
              key: HistogramGroupBy.JOIN_DA,
              value: HistogramGroupBy.JOIN_DA,
              text: 'Join DA',
            },
            {
              key: HistogramGroupBy.PARTY,
              value: HistogramGroupBy.PARTY,
              text: 'Party',
            },
            {
              key: HistogramGroupBy.VOTER_CLASS,
              value: HistogramGroupBy.VOTER_CLASS,
              text: 'Voter class',
            },
            {
              key: HistogramGroupBy.VOTING_STATE,
              value: HistogramGroupBy.VOTING_STATE,
              text: 'Voting state',
            },
          ]}
          selection
          onChange={(_evt, data) =>
            setGroupBy(data.value as HistogramGroupBy | null)
          }
        />
      </Form.Field>
      <Form.Field>
        <Button
          primary
          content="Analyze data"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => onQuery(collateQueryData())}
        />
      </Form.Field>
      {formHasChanges && (
        <Form.Field>
          <Button onClick={resetForm}>Reset form</Button>
        </Form.Field>
      )}
    </Form>
  );
};

export default LogTimeseriesControls;
